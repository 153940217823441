/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, Box, Grid, Snackbar} from '@mui/material';
import {useEffect, useState} from 'react';
import {BaseLayer} from '../../components/common/BaseLayer/BaseLayer';
import {BasicBtn} from '../../components/common/BasicBtn/BasicBtn';
import {PasswordInput} from '../../components/common/PasswordInput/PasswordInput';
import {useSnackBar} from '../../hooks/useSnackBar';
import {
  accSubTitle,
  accTitle,
  basicInfoContainer,
  basicInfoGridContainer,
  basicInfoRow,
  basicInfoTitle,
  bRBox,
  bRBtn,
  pageContainer,
} from '../AccountView/AccountStyles';
import {app, changePassword, getUserAttributes} from '../../providers/Account';

export const SecurityView = () => {
  const {snack, severity, snackMsg, handleSnack, closeSnack} = useSnackBar();
  const [oldPw, setOldPw] = useState({
    value: '',
    show: false,
  });
  const [newPw, setNewPw] = useState({
    value: '',
    show: false,
  });
  const [confNewPw, setConfNewPw] = useState({
    value: '',
    show: false,
  });
  const pwLength = 8;
  const [empty, setEmpty] = useState(false);

  // To be implemented after sdk is ready
  // if the result is empty, undefined or false then the user is not using google-login
  // useEffect(() => {
  //   getUserAttributes(app, 'isFederatedUser')
  //     .then(res => {
  //       if (!res) {
  //         setEmpty(false);
  //       } else {
  //         setEmpty(true);
  //       }
  //     })
  //     .catch(err => console.log(err));
  // }, []);

  const handleOldPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setOldPw({...oldPw, value: event.target.value});

  const handleOldPwIcon = () => setOldPw({...oldPw, show: !oldPw.show});

  const handleNewPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNewPw({...newPw, value: event.target.value});

  const handleNewPwIcon = () => setNewPw({...newPw, show: !newPw.show});

  const handleConfNewPw = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfNewPw({...confNewPw, value: event.target.value});

  const handleConfNewPwIcon = () =>
    setConfNewPw({...confNewPw, show: !confNewPw.show});

  const checkPw =
    oldPw.value.length < pwLength ||
    newPw.value.length < pwLength ||
    confNewPw.value.length < pwLength;

  const sameNewPw = () => newPw.value === confNewPw.value;

  const emptyFields = () => {
    setOldPw({...oldPw, value: ''});
    setNewPw({...newPw, value: ''});
    setConfNewPw({...confNewPw, value: ''});
  };

  const handlePwChange = () => {
    changePassword(app, oldPw.value, newPw.value)
      .then(() => {
        handleSnack('success', 'Your changes have been submitted');
        emptyFields();
      })
      .catch(err => handleSnack('error', `${err.code} , ${err.err}`));
  };

  const submitPw = () =>
    sameNewPw()
      ? handlePwChange()
      : handleSnack('error', 'New passwords do not match');

  return (
    <BaseLayer>
      <Box sx={pageContainer}>
        <Box>
          <Box sx={accTitle}>Security Info</Box>
          <Box sx={accSubTitle}>
            Settings to help you keep your account secure
          </Box>
          <Box sx={{height: '50px'}} />
          {!empty && (
            <Box sx={basicInfoContainer}>
              <Box sx={basicInfoTitle}>Change Password</Box>
              <Box sx={{my: 2}}>
                <Box sx={{color: 'black'}}>Password strength:</Box>
                <Box sx={{fontSize: '12px', color: 'black'}}>
                  Use at least 8 characters. Don&apos;t use a password from
                  another site, or something too obvious like your pet&apos;s
                  name.
                </Box>
              </Box>
              <Grid container sx={basicInfoGridContainer}>
                <Box sx={basicInfoRow}>
                  <Grid item xs={4}>
                    Current Password
                  </Grid>
                  <Grid item xs={8}>
                    <PasswordInput
                      type={oldPw.show}
                      onChange={handleOldPw}
                      value={oldPw.value}
                      onClickIcon={handleOldPwIcon}
                    />
                  </Grid>
                </Box>
                <Box sx={basicInfoRow}>
                  <Grid item xs={4}>
                    New Password
                  </Grid>
                  <Grid item xs={8}>
                    <PasswordInput
                      type={newPw.show}
                      onChange={handleNewPw}
                      value={newPw.value}
                      onClickIcon={handleNewPwIcon}
                      placeholder="Enter your new password"
                    />
                  </Grid>
                </Box>
                <Box sx={basicInfoRow}>
                  <Grid item xs={4}>
                    Confirm New Password
                  </Grid>
                  <Grid item xs={8}>
                    <PasswordInput
                      type={confNewPw.show}
                      onChange={handleConfNewPw}
                      value={confNewPw.value}
                      onClickIcon={handleConfNewPwIcon}
                      placeholder="Confirm your new password"
                    />
                  </Grid>
                </Box>
                <Box sx={bRBox}>
                  <Box sx={bRBtn}>
                    <BasicBtn
                      name="save"
                      action={submitPw}
                      disabled={checkPw}
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snack}
        onClose={closeSnack}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={6000}
      >
        <Alert severity={severity}>{snackMsg}</Alert>
      </Snackbar>
    </BaseLayer>
  );
};

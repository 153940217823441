/* eslint-disable @typescript-eslint/no-explicit-any */
import {Logout} from '@mui/icons-material';
import {AppBar, Box, Grid, Stack, Toolbar, Tooltip} from '@mui/material';
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../../../providers/AuthProvider';
import {NewDigistaffLogoFullWhite} from '../../Image/Image';
import {AppsMenu} from './AppsMenu';

export const NavBar = () => {
  const navigate = useNavigate();
  const {logOut} = useContext(AuthContext);

  const goToMain = () => navigate('/myaccount');

  const signOut = () => logOut();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            onClick={goToMain}
            sx={brandLogo}
            src={NewDigistaffLogoFullWhite}
          />
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <AppsMenu />
            <Tooltip title="Logout">
              <Logout sx={{cursor: 'pointer'}} onClick={signOut} />
            </Tooltip>
          </Stack>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const brandLogo = {
  height: '4.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

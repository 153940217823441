import {Button} from '@mui/material';
import {BasicBtnProps} from '../../../types/types';
import {basicBtn} from '../../../views/AccountView/AccountStyles';

export const BasicBtn = ({name, action, disabled, customSx}: BasicBtnProps) => {
  return (
    <Button
      variant="outlined"
      sx={customSx ? customSx : basicBtn}
      onClick={action}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

import {Box} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {listItems} from './SideBarItem';

export const SideBar = () => {
  const navigate = useNavigate();
  const url = useLocation().pathname;

  return (
    <Box sx={sideBarContainer}>
      <Box sx={{height: '20px'}} />
      {listItems.map(i => (
        <Box
          key={i.title}
          sx={i.link === url ? sideBarListBoxAlt : sideBarListBox}
          onClick={() => navigate(i.link)}
        >
          <i.icon sx={sideBarIcon} />
          <Box sx={sideBarList}>{i.title}</Box>
        </Box>
      ))}
    </Box>
  );
};

const sideBarContainer = {
  width: '300px',
  height: '100vh',
  boxShadow: 5,
  position: 'fixed',
  zIndex: 999,
};

const sideBarListBox = {
  pl: '20px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

const sideBarListBoxAlt = {
  pl: '20px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: 'primary.main',
  color: 'primary.light',
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
};

const sideBarIcon = {
  mr: '14px',
};

const sideBarList = {
  textTransform: 'capitalize',
};

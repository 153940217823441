import {AlertColor} from '@mui/material';
import {useState} from 'react';

export const useSnackBar = () => {
  const [snack, setSnack] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);
  const [snackMsg, setSnackMsg] = useState('');

  const handleSnack = (status: AlertColor, msg: string) => {
    setSnack(true);
    setSeverity(status);
    setSnackMsg(msg);
  };

  const closeSnack = () => setSnack(false);

  return {snack, severity, snackMsg, handleSnack, closeSnack};
};

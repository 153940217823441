/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import {useWindowSize} from '../../../hooks/useWindowSize';
import {NavBar} from './NavBar/NavBar';
import {SecondBar} from './SideBar/SecondBar';
import {SideBar} from './SideBar/SideBar';

export const BaseLayer = ({children}: any) => {
  const windowSize = useWindowSize();

  return (
    <Box sx={{position: 'relative'}}>
      <NavBar />
      {windowSize.width > 768 ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            pt: '64px',
          }}
        >
          <SideBar />
          <Box sx={{width: '100%', position: 'relative', pl: '300px'}}>
            {children}
          </Box>
        </Box>
      ) : (
        <>
          <SecondBar />
          <Box sx={{width: '100%', position: 'relative'}}>{children}</Box>
        </>
      )}
    </Box>
  );
};

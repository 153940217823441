import {Box} from '@mui/material';

export const TBCView = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      To be Developed!
    </Box>
  );
};

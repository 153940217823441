/* eslint-disable @typescript-eslint/no-explicit-any */
import {getOpsProfile, updateOpsProfile} from '@digistaff/business';
import {app} from './Account';

export const fetchOpsProfile = async (opsId: string) => {
  try {
    const res = await getOpsProfile(app, opsId);

    return res.data.getOpsProfile;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateOpsProfileInAccounts = async (
  opsId: string,
  payload: any
) => {
  try {
    return await updateOpsProfile(app, opsId, payload);
  } catch (error) {
    return Promise.reject(error);
  }
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useContext} from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';
import {useCurrentTabTitle} from '../../hooks/useCurrentTabTitle';
import {AuthContext} from '../../providers/AuthProvider';

export const PublicRoute = ({children}: any) => {
  document.title = useCurrentTabTitle();
  const {currentUser} = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  if (Object.keys(currentUser).length > 0) {
    const continueUrl = searchParams.get('continue');
    const goToAdmin = searchParams.get('admin');

    if (continueUrl === null) {
      return <Navigate to="/myaccount" replace />;
    } else {
      const adminUrl = `/tenants?continue=${continueUrl}&admin=true`;
      const businessUrl = `/tenants?continue=${continueUrl}`;
      if (goToAdmin) return <Navigate to={adminUrl} replace />;
      else return <Navigate to={businessUrl} replace />;
    }
  }

  return children;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState} from 'react';

export const useProfileImage = () => {
  const [imageUrl, setImageUrl] = useState('');

  const handleImgChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => setImageUrl(reader.result as string);
  };

  return {imageUrl, handleImgChange};
};

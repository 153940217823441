/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare let google: any;

import jwt_decode from 'jwt-decode';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {
  Snackbar,
  Alert,
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import {EmailOutlined, LockOutlined} from '@mui/icons-material';

import emailAddress from '../../../assets/js/formvalidation/es6/validators/emailAddress';
import stringLength from '../../../assets/js/formvalidation/es6/validators/stringLength';
import {
  app,
  signInWithEmailAndPassword,
  googleLogin,
} from '../../providers/Account';
import {
  centralBox,
  flexCtrCtr,
  gridContainer,
  signInTextField,
} from './SignInStyles';
import {
  fetchOpsProfile,
  updateOpsProfileInAccounts,
} from '../../providers/opsProfile';

export interface ISignInForm {
  email: string;
  password: string;
}

export interface ISignInFormError {
  email: string;
  password: string;
  invalidEmailOrPw: string;
}

export type SignInProps = {
  name: string;
  value: string;
};

export const SignInView = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<ISignInForm>({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState<ISignInFormError>({
    email: '',
    password: '',
    invalidEmailOrPw: '',
  });
  const [isSignInError, setIsSignInError] = useState(false);
  const [signInErrorMessage, setSignInErrorMessage] = useState('Error');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const googleButtonContainer = document.getElementById('google-sign-in-box');

    google.accounts.id.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID,
      callback: (data: any) =>
        handleGoogleLogin(data.credential, jwt_decode(data.credential)),
    });

    google.accounts.id.renderButton(googleButtonContainer, {
      theme: 'filled_black',
      size: 'large',
      width: document.getElementById('signInContainer')!.offsetWidth,
    });

    const handleGoogleLogin = async (token: string, data: any) => {
      const user = {
        name: data.name,
        email: data.email,
        first_name: data.given_name,
        family_name: data.family_name,
      };

      googleLogin(app, token, data.exp, user).then(({user}: any) => {
        const opsId = user?.username;
        fetchOpsProfile(opsId).then(res => {
          if (res !== null && res.current_onboarding_step === 0) {
            const payload = {
              current_onboarding_step: 1,
              first_name: data.given_name,
              last_name: data.family_name,
            };
            updateOpsProfileInAccounts(opsId, payload).then(() => {
              handleRedirection(user);
            });
          } else {
            handleRedirection(user);
          }
        });
      });
    };
  }, [navigate]);

  const handleForm = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm({...form, [name]: value});
  };

  /**
   * validate if the email address is valid and the password length is over 8 char.
   * @param e - input value from user
   * return : set formError accordingly
   */
  const validateField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case 'email': {
        const result = emailAddress().validate({
          value,
        });
        result.valid === false
          ? setFormError({
              ...formError,
              [name]: 'The value is not a valid email address',
            })
          : setFormError({...formError, [name]: ''});
        break;
      }
      case 'password':
        {
          const result = stringLength().validate({
            value,
            options: {
              min: 8,
              max: 20,
            },
          });
          result.valid === false
            ? setFormError({
                ...formError,
                [name]: 'The value is not a valid password',
              })
            : setFormError({...formError, [name]: ''});
        }
        break;
    }
  };

  const handleSubmit = () => {
    if (
      Object.values(formError.email).length > 0 ||
      Object.values(formError.password).length > 0
    ) {
      setFormError({
        ...formError,
        invalidEmailOrPw: 'Incorrect Email or Password',
      });
    }

    signInWithEmailAndPassword(app, form.email, form.password)
      .then(({user}: any) => {
        const opsId = user?.username;
        fetchOpsProfile(opsId)
          .then(res => {
            if (res.current_onboarding_step === 0) {
              const payload = {
                current_onboarding_step: 1,
              };
              updateOpsProfileInAccounts(opsId, payload)
                .then(() => {
                  handleRedirection(user);
                })
                .catch(err => {
                  console.log(err);
                });
            } else {
              setFormError({...formError, invalidEmailOrPw: ''});
              handleRedirection(user);
            }
          })
          .catch(err => {
            console.log(err);
            console.log(err);
          });
      })
      .catch(({err}) => {
        console.log(err);
        setSignInErrorMessage(err);
        setIsSignInError(true);
        setFormError({...formError, invalidEmailOrPw: err});
      });
  };

  useEffect(() => {
    const clear = searchParams.get('clear');
    if (clear) localStorage.clear();
  }, []);

  const handleRedirection = (user: any) => {
    localStorage.setItem('auth', JSON.stringify(user));
    let site = searchParams.get('site');
    let continueUrl = searchParams.get('continue');

    if (!site) site = 'business';
    if (!continueUrl || continueUrl === '/') continueUrl = '';

    navigate(`/tenants?site=${site}&continue=${continueUrl}`);
  };

  const handleCloseErrorToaster = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSignInError(false);
  };

  return (
    <>
      <Box sx={centralBox}>
        <Grid container direction="column" sx={gridContainer}>
          <Grid item>
            <Typography variant="h3" component="h2" fontWeight="fontWeightBold">
              Sign in
            </Typography>
            <Box sx={{mt: '1rem', pb: '1rem'}}>
              <div style={flexCtrCtr} id="google-sign-in-box"></div>
            </Box>
          </Grid>
          <Grid item my={1.5} id="signInContainer">
            <Typography variant="body1" component="h6">
              or use your email to sign in:
            </Typography>
            <Stack direction="column" spacing={2} mt={2}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Work email"
                name="email"
                type="email"
                value={form.email}
                error={Boolean(formError.email || formError.invalidEmailOrPw)}
                helperText={formError.email}
                onChange={e => handleForm(e)}
                onBlur={e => validateField(e)}
                sx={signInTextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Password"
                name="password"
                type="password"
                onChange={e => handleForm(e)}
                error={Boolean(
                  formError.password || formError.invalidEmailOrPw
                )}
                helperText={formError.password}
                onBlur={e => validateField(e)}
                sx={signInTextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Box sx={{pb: '0.5rem'}}>
            <Button
              sx={{fontSize: 'body2'}}
              onClick={() => navigate('/password/forget')}
            >
              I forgot my password
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              fullWidth={true}
              sx={{fontWeight: 'fontWeightMedium'}}
              disabled={
                Object.values(formError.email).length > 0 ||
                Object.values(formError.password).length > 0
              }
              onClick={handleSubmit}
            >
              sign in
            </Button>
          </Box>
        </Grid>
      </Box>
      <Snackbar
        open={isSignInError}
        autoHideDuration={5000}
        onClose={handleCloseErrorToaster}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert
          severity="error"
          sx={{width: '100%'}}
          onClose={handleCloseErrorToaster}
        >
          {`${signInErrorMessage}`}
        </Alert>
      </Snackbar>
    </>
  );
};

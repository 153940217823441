/* eslint-disable @typescript-eslint/no-explicit-any */
import {Close, PhotoCamera} from '@mui/icons-material';
import {Avatar, Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {basicBtn} from '../../../views/AccountView/AccountStyles';
import {flexCtrCtr} from '../../../views/SignInView/SignInStyles';
import {BasicBtn} from '../BasicBtn/BasicBtn';

export const PhotoModal = ({
  close,
  uploadPic,
  imageUrl,
  handleImgChange,
}: any) => {
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    imageUrl !== '' && setUpload(true);
  }, [imageUrl]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '35vw',
        height: '50vh',
        bgcolor: 'primary.light',
        boxShadow: 24,
        borderRadius: '8px',
        outline: 'none',
        overflow: 'scroll',
        p: 2,
      }}
    >
      <Close
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          cursor: 'pointer',
        }}
        onClick={close}
      />
      <Box sx={{m: 5}}>
        <Box sx={flexCtrCtr}>
          <Avatar sx={{width: '300px', height: '300px'}} src={imageUrl} />
        </Box>
      </Box>
      <Box sx={{m: 2}}>
        {!upload ? (
          <Button variant="outlined" sx={basicBtn} component="label">
            <PhotoCamera />
            Upload Image
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={handleImgChange}
            />
          </Button>
        ) : (
          <BasicBtn name="submit" action={uploadPic} />
        )}
      </Box>
    </Box>
  );
};

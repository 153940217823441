export const pageContainer = {
  display: 'flex',
  flexDirection: 'column',
  py: 2,
  pl: {xs: 2, md: '75px', lg: '150px', xl: '300px'},
  pr: {xs: 2, md: '125px', lg: '250px', xl: '500px'},
};

export const basicInfoRow = {
  my: 2,
  display: 'flex',
  alignItems: 'center',
};

export const accTitle = {fontSize: '28px', textAlign: 'center'};

export const accSubTitle = {
  fontSize: '14px',
  textAlign: 'center',
  color: 'secondary.main',
};

export const basicInfoContainer = {
  border: '1px solid',
  borderColor: 'secondary.light',
  borderRadius: '8px',
  position: 'relative',
  mt: 1,
  p: 4,
};

export const basicInfoTitle = {fontSize: '20px', mb: '20px'};

export const basicInfoGridContainer = {
  display: 'flex',
  flexDirection: 'column',
};

export const userIconContainer = {
  position: 'absolute',
  height: '100px',
  width: '100px',
  border: '1px solid',
  borderColor: 'secondary.main',
  top: '-50px',
  right: '',
  borderRadius: '50%',
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
};

export const basicBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'primary.main',
  },
  '&:disabled': {
    backgroundColor: 'secondary.main',
  },
};

export const bRBox = {display: 'flex', justifyContent: 'flex-end', mt: '20px'};

export const bRBtn = {width: '150px'};

export const discardBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.light',
  color: 'warning.main',
  border: '1px solid',
  borderColor: 'warning.main',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'warning.main',
    borderColor: 'warning.main',
  },
};

export const saveBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.light',
  color: 'success.main',
  border: '1px solid',
  borderColor: 'success.main',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'success.main',
    borderColor: 'success.main',
  },
};

export const centralBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '5rem',
  width: '100%',
};

export const gridContainer = {
  width: {
    xs: '337px',
    sm: '337px',
    md: '337px',
    lg: '400px',
  },
  maxWidth: '400px',
  p: 0,
};

export const flexCtrCtr = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const signInTextField = {
  textAlign: 'left',
  '& .MuiInputBase-root': {
    maxHeight: '5rem',
  },
};

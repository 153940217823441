import RouteErrorView from '../views/RouteErrorView/RouteErrorView';
import {AccountView} from '../views/AccountView/AccountView';
import {SecurityView} from '../views/SecurityView/SecurityView';
import {PreferenceView} from '../views/PreferenceView/PreferenceView';
import {PrivateRoute} from './guards/PrivateRoute';
import {TenantView} from '../views/TenantView/TenantView';

export const privateRoutes = [
  {
    path: '/myaccount',
    element: (
      <PrivateRoute>
        <AccountView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/security',
    element: (
      <PrivateRoute>
        <SecurityView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/preference',
    element: (
      <PrivateRoute>
        <PreferenceView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/tenants',
    element: (
      <PrivateRoute>
        <TenantView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Stack,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import {recoverContainer, recoverTextField} from './PasswordRecoveryStyles';
import {BasicBtn} from '../../components/common/BasicBtn/BasicBtn';
import {app, forgotPassword} from '../../providers/Account';
import {useSnackBar} from '../../hooks/useSnackBar';
import {confirmForgotPassword} from '@digistaff/app';
import {PasswordInput} from '../../components/common/PasswordInput/PasswordInput';

export const PasswordForgetView = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const {snack, severity, snackMsg, handleSnack, closeSnack} = useSnackBar();
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPw, setShowPw] = useState(false);

  const goToHome = () => navigate('/');

  const handleSubmit = () => {
    const typedEmail = email.toLowerCase().trim();

    if (!typedEmail) {
      handleSnack('error', 'Please enter email');
      return;
    }

    forgotPassword(app, email)
      .then(() => {
        handleSnack('success', 'Code has been sent to the email');
      })
      .catch(err => {
        if (err.code === 'DS_USER_FORGOT_PASS_FAILED') {
          handleSnack('error', 'Email not found');
        } else {
          console.log('error', err);
          console.log(err);
        }
      });
  };

  const handleResetPw = () => {
    confirmForgotPassword(app, email, newPassword, code)
      .then(() => {
        handleSnack(
          'success',
          'Password successfully changed. Redirecting you to the login page now...'
        );
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch(({err}: any) => {
        handleSnack('error', 'Unable to reset password');
        console.log(err);
      });
  };

  return (
    <>
      <Box sx={recoverContainer}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography variant="h3" component="h2">
            Password recovery
          </Typography>
          <Typography variant="body1" component="h2">
            Provide your registered email to receive verification code
          </Typography>
        </Stack>
        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
          placeholder="Input email"
          sx={recoverTextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{fontSize: '14px'}} onPointerUp={handleSubmit}>
                  Send
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          placeholder="Enter your 6 digit verification code here"
          value={code}
          onChange={e => setCode(e.target.value)}
          variant="outlined"
          sx={recoverTextField}
        />
        <PasswordInput
          placeholder="Enter new password"
          type={showPw}
          style={recoverTextField}
          name="password"
          onChange={(e: any) => setNewPassword(e.target.value)}
          value={newPassword}
          onClickIcon={() => setShowPw(!showPw)}
        />
        <Box sx={{width: {md: '25vw', sm: '60vw'}}}>
          <BasicBtn
            name="Reset Password"
            action={handleResetPw}
            disabled={!newPassword || !code}
          />
        </Box>
        <Box onClick={goToHome} sx={{cursor: 'pointer'}}>
          Back to Sign In
        </Box>
      </Box>
      <Snackbar
        open={snack}
        onClose={closeSnack}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={6000}
      >
        <Alert severity={severity}>{snackMsg}</Alert>
      </Snackbar>
    </>
  );
};

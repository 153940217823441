import {getOpsProfile} from '@digistaff/business';
import {app} from '../../Account';

export const fetchOpsName = async (opsId: string) => {
  try {
    const res = await getOpsProfile(app, opsId);
    const profile = res.data.getOpsProfile;
    const returnProfile = `${profile.first_name} ${profile.last_name}`;

    return returnProfile;
  } catch (err) {
    return err;
  }
};

export const fetchOpsTenants = async (opsId: string) => {
  try {
    const res = await getOpsProfile(app, opsId);
    const profile = res.data.getOpsProfile;

    return profile.tenants;
  } catch (err) {
    return err;
  }
};

export const getCurrentLoggedOps = () => {
  try {
    return app.session.lastAuthUser || app.user.username;
  } catch (err) {
    return err;
  }
};

import {Box} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {listItems} from './SideBarItem';

export const SecondBar = () => {
  const navigate = useNavigate();
  const url = useLocation().pathname;

  return (
    <Box sx={secondBarContainer}>
      {listItems.map(i => (
        <Box key={i.title} sx={secondBarItem} onClick={() => navigate(i.link)}>
          <Box sx={i.link === url ? secondBartextAlt : secondBartext}>
            {i.title}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const secondBarContainer = {
  height: '50px',
  flexGrow: 1,
  mt: '64px',
  borderBottom: '1px solid',
  borderColor: 'secondary.main',
  display: 'flex',
  alignItems: 'center',
};

const secondBarItem = {mx: 2, cursor: 'pointer'};

const secondBartext = {
  textTransform: 'capitalize',
};

const secondBartextAlt = {
  textTransform: 'capitalize',
  textDecoration: 'underline',
  textUnderlineOffset: '8px',
};

export const recoverTextField = {
  width: {md: '25vw', sm: '60vw'},
  textAlign: 'left',
  '& .MuiInputBase-root': {
    height: '100%',
    maxHeight: '5rem',
  },
};

export const recoverContainer = {
  height: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
};

export const recoverBtn = {px: '1.5rem', height: '3.5rem'};

export const recoverTextFieldBox = {
  display: 'flex',
  mt: 2,
};

export const resetContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  justifyContent: 'center',
  textAlign: 'center',
};

export const resetBtnBox = {
  mt: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
};

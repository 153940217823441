import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {PasswordInputProps} from '../../../types/types';

export const PasswordInput = ({
  type,
  name,
  onChange,
  value,
  onClickIcon,
  placeholder,
  style,
}: PasswordInputProps) => {
  return (
    <TextField
      type={type ? 'text' : 'password'}
      sx={
        style
          ? style
          : {
              width: '100%',
              backgroundColor: 'secondary.main',
              border: 'none',
              '& .MuiInput-input': {
                marginLeft: '10px',
              },
            }
      }
      name={name}
      placeholder={placeholder ? placeholder : 'Enter your password'}
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClickIcon}>
              {type ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useSearchParams} from 'react-router-dom';
import {
  Stack,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  styled,
  tableCellClasses,
  TablePagination,
  CircularProgress,
} from '@mui/material';

import {
  fetchOpsTenants,
  getCurrentLoggedOps,
} from '../../providers/api/Ops/ops';
import {getTenant} from '../../providers/api/Tenant/tenant';
import {useContext, useEffect, useState} from 'react';
import {app, setTenantKey, signOut} from '../../providers/Account';
import {getOpsProfileTenant, updateOpsProfile} from '@digistaff/business';
import {AuthContext} from '../../providers/AuthProvider';

function createData(
  id: string,
  name: string,
  businesses: number,
  workers: number,
  businesse_locations: number
) {
  return {id, name, businesses, workers, businesse_locations};
}

export const TenantView = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [searchParams] = useSearchParams();
  const {logOut} = useContext(AuthContext);

  const listTenants = async (opsId: string) => {
    const tenantRows: any = [];
    const tenants = await fetchOpsTenants(opsId);

    for (let step = 0; step < tenants.length; step++) {
      const tenantProfile = await getTenant(tenants[step]);
      tenantRows.push(
        createData(
          tenantProfile.id,
          tenantProfile.name,
          tenantProfile.businesses,
          tenantProfile.staff,
          tenantProfile.businesse_locations
        )
      );
    }

    return tenantRows;
  };

  const isLocal = window.location.origin === process.env.LOCAL_ACCOUNTS_URL;

  const handleRedirection = async (tenantKey: string) => {
    let accessToken;
    let idToken;
    let refreshToken;
    let username;
    if (!user) {
      const auth = localStorage.getItem('auth');
      if (auth) {
        const currentUser = JSON.parse(auth);
        accessToken = currentUser.AuthenticationResult.AccessToken;
        idToken = currentUser.AuthenticationResult.IdToken;
        refreshToken = currentUser.AuthenticationResult.RefreshToken;
        username = currentUser.username;
      }
    } else {
      accessToken = user.AuthenticationResult.AccessToken;
      idToken = user.AuthenticationResult.IdToken;
      refreshToken = user.AuthenticationResult.RefreshToken;
      username = user.username;
    }

    const redirectBusinessUrl = isLocal
      ? process.env.LOCAL_BUSINESS_URL
      : process.env.DS_BUSINESS_URL;

    const redirectAdminUrl = isLocal
      ? process.env.LOCAL_ADMIN_URL
      : process.env.DS_ADMIN_URL;

    const redirectInternalUrl = isLocal
      ? process.env.LOCAL_INTERNAL_URL
      : process.env.DS_INTERNAL_URL;

    const redirectAccountsUrl = isLocal
      ? process.env.LOCAL_ACCOUNTS_URL
      : process.env.DS_ACCOUNTS_URL;

    const opsId = searchParams.get('username') || getCurrentLoggedOps();
    const tenantProfile = await getOpsProfileTenant(app, opsId);
    let continueUrl = searchParams.get('continue');

    const role = tenantProfile.data.getOpsProfileTenant.role;
    localStorage.setItem('role', role);
    const status = tenantProfile.data.getOpsProfileTenant.status;

    if (continueUrl === '/') continueUrl = null;
    const site = searchParams.get('site');

    if (site === 'business') {
      if (!continueUrl) continueUrl = 'main';
      window.location.href = `${redirectBusinessUrl}?tenant=${tenantKey}&username=${username}&accessToken=${accessToken}&idToken=${idToken}&refreshToken=${refreshToken}&role=${role}&status=${status}&continue=${continueUrl}`;
    } else if (site === 'admin') {
      if (!continueUrl) continueUrl = 'general';
      window.location.href = `${redirectAdminUrl}?tenant=${tenantKey}&username=${username}&accessToken=${accessToken}&idToken=${idToken}&refreshToken=${refreshToken}&role=${role}&status=${status}&continue=${continueUrl}`;
    } else if (site === 'internal') {
      if (!continueUrl) continueUrl = 'stats';
      window.location.href = `${redirectInternalUrl}?tenant=${tenantKey}&username=${username}&accessToken=${accessToken}&idToken=${idToken}&refreshToken=${refreshToken}&role=${role}&status=${status}&continue=${continueUrl}`;
    } else {
      window.location.href = `${redirectAccountsUrl}`;
    }
  };

  const handleNavigate = (tenant: any): any => {
    const opsId = searchParams.get('username') || getCurrentLoggedOps();

    setTenantKey(app, tenant.id);
    updateOpsProfile(app, opsId, {current_selected_tenant: tenant.id}).then(
      () => handleRedirection(tenant.id)
    );
  };

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth !== null) {
      setUser(JSON.parse(auth));
      listTenants(getCurrentLoggedOps()).then(res => {
        setRows(res);
        setLoading(false);
        if (res.length === 1) handleNavigate(res[0]);
      });
    } else {
      localStorage.clear();
      signOut(app).then(() => {
        window.location.href = '/';
      });
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3E82BF',
      color: theme.palette.common.white,
    },
  }));

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        {loading && <CircularProgress />}
        {!loading && rows.length > 0 && (
          <Stack spacing={2}>
            <Typography
              variant="h3"
              component="h3"
              fontWeight="fontWeightBold"
              marginTop="75px"
            >
              Choose a tenant
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="Tenant Table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tenant</StyledTableCell>
                    <StyledTableCell align="right">Businesses</StyledTableCell>
                    <StyledTableCell align="right">
                      Business Locations
                    </StyledTableCell>
                    <StyledTableCell align="right">Workers</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((row: any) => (
                    <TableRow
                      key={row.name}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell
                        style={{cursor: 'pointer'}}
                        onClick={() => handleNavigate(row)}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.businesses}</TableCell>
                      <TableCell align="right">
                        {row.businesse_locations}
                      </TableCell>
                      <TableCell align="right">{row.workers}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Stack>
        )}
        {!loading && rows.length === 0 && (
          <Box
            sx={{
              textAlign: 'center',
              padding: 5,
              justifyContent: 'center',
            }}
          >
            You are currently not associated with any agencies/businesses.
            <Typography
              onClick={() => logOut()}
              sx={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click here to log out
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

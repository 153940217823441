/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AuthContextInterface} from '../types/types';
import {app, getCurrentUser, signOut} from '../providers/Account';
const querystring = require('querystring');

export const AuthContext = createContext<AuthContextInterface>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [currentUser, setCurrentUser] = useState({});

  const logOut = useCallback(() => {
    localStorage.clear();
    signOut(app).then(() => {
      window.location.href = '/';
    });
  }, []);

  const login = (user: any) => {
    setCurrentUser({user});
  };

  const checkLogOut = () => {
    const queryString = window.location.search.substring(1);
    const queryParams = querystring.parse(queryString);
    if (queryParams.action === 'logout') {
      logOut();
    }
  };

  useEffect(() => {
    checkLogOut();
    getCurrentUser(app)
      .then(({user}: any) => {
        login(user);
      })
      .catch(() => {
        if (window.location.pathname !== '/') logOut();
      });
  }, []);

  return (
    <AuthContext.Provider value={{currentUser, login, logOut}}>
      {children}
    </AuthContext.Provider>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, Box, Grid, MenuItem, Snackbar, TextField} from '@mui/material';
import {useState} from 'react';
import {BaseLayer} from '../../components/common/BaseLayer/BaseLayer';
import {BasicBtn} from '../../components/common/BasicBtn/BasicBtn';
import {useSnackBar} from '../../hooks/useSnackBar';
import {
  accSubTitle,
  accTitle,
  basicInfoContainer,
  basicInfoGridContainer,
  basicInfoRow,
  basicInfoTitle,
  bRBox,
  bRBtn,
  pageContainer,
} from '../AccountView/AccountStyles';

export const PreferenceView = () => {
  const {snack, severity, snackMsg, handleSnack, closeSnack} = useSnackBar();
  const [lang, setLang] = useState('english');
  const langTemp = 'english';

  const handleLang = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLang(e.target.value);

  const checkLang = lang === langTemp;

  const submitLang = () => {
    handleSnack('success', 'Your changes have been submitted');
  };

  return (
    <BaseLayer>
      <Box sx={pageContainer}>
        <Box>
          <Box sx={accTitle}>Preference</Box>
          <Box sx={accSubTitle}>Make DigiStaff work better for you</Box>
        </Box>
        <Box sx={{height: '50px'}} />
        <Box sx={basicInfoContainer}>
          <Box sx={basicInfoTitle}>Change Language</Box>
          <Grid container sx={basicInfoGridContainer}>
            <Box sx={basicInfoRow}>
              <Grid item xs={4}>
                Language:
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{width: '100%', textTransform: 'capitalize'}}
                  select
                  inputProps={{sx: {ml: 2}}}
                  value={lang}
                  onChange={handleLang}
                >
                  {languageList.map(lang => (
                    <MenuItem
                      key={lang}
                      value={lang}
                      sx={{textTransform: 'capitalize'}}
                    >
                      {lang}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Box>
            <Box sx={bRBox}>
              <Box sx={bRBtn}>
                <BasicBtn
                  name="submit"
                  action={submitLang}
                  disabled={checkLang}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={snack}
        onClose={closeSnack}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={6000}
      >
        <Alert severity={severity}>{snackMsg}</Alert>
      </Snackbar>
    </BaseLayer>
  );
};

const languageList = ['english', 'french', 'chinese'];

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Avatar,
  Box,
  Grid,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {BaseLayer} from '../../components/common/BaseLayer/BaseLayer';
import {BasicBtn} from '../../components/common/BasicBtn/BasicBtn';
import {PhotoModal} from '../../components/common/PhotoModal/PhotoModal';
import {useProfileImage} from '../../hooks/useProfileImage';
import {useSnackBar} from '../../hooks/useSnackBar';
import {
  accSubTitle,
  accTitle,
  basicInfoContainer,
  basicInfoGridContainer,
  basicInfoRow,
  basicInfoTitle,
  bRBox,
  bRBtn,
  discardBtn,
  pageContainer,
  saveBtn,
  userIconContainer,
} from './AccountStyles';
import {getOpsProfile, updateOpsProfile} from '@digistaff/business';
import {getCurrentUser} from '@digistaff/app';
import {app} from '../../providers/Account';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

export const AccountView = () => {
  const [editBasic, setEditBasic] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [modal, setModal] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const {snack, severity, snackMsg, handleSnack, closeSnack} = useSnackBar();
  const {imageUrl, handleImgChange} = useProfileImage();
  const [opsId, setOpsId] = useState('');
  const [profile, setProfile] = useState<any>(null);
  const [state, setState] = useState<any>(null);

  const handleEditBasic = () => setEditBasic(!editBasic);
  const handleEditContact = () => setEditContact(!editContact);
  const handleModal = () => setModal(!modal);

  const uploadPic = () => {
    setProfilePic(imageUrl);
    handleSnack('success', 'Your new profile picture has been submitted');
    handleModal();
  };

  const resetBasicInfo = () => {
    setState({
      ...state,
      first_name: profile.first_name,
      last_name: profile.last_name,
      birthdate: profile.birth_date,
      gender: profile.gender,
    });
    handleEditBasic();
  };

  const resetContactInfo = () => {
    setState({
      ...state,
      email: profile.email,
      phone: profile.phone,
    });
    handleEditContact();
  };

  useEffect(() => {
    getCurrentUser(app)
      .then((res: any) => {
        setOpsId(res.user.Username);
        getOpsProfile(app, res.user.Username)
          .then((user: any) => {
            setProfile(user.data.getOpsProfile);
            setState(user.data.getOpsProfile);
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  }, []);

  const updateProfile = () => {
    const payload: any = {
      first_name: state.first_name,
      last_name: state.last_name,
      birthdate: state.birthdate,
      gender: state.gender,
      email: state.email,
      phone: state.phone,
    };

    let valid = true;
    Object.keys(payload).forEach(value => {
      if (payload[value] === '') valid = false;
    });

    if (valid) {
      updateOpsProfile(app, opsId, payload)
        .then(() => {
          handleSnack('success', 'Your changes have been submitted');
        })
        .catch(err => handleSnack('error', err));
    } else {
      handleSnack('info', 'Please fill in all the fields!');
    }
  };

  return (
    <BaseLayer>
      {profile && (
        <Box sx={pageContainer}>
          <Box>
            <Box sx={accTitle}>Personal Info</Box>
            <Box sx={accSubTitle}>The info summary about you</Box>
          </Box>
          <Box sx={{height: '80px'}} />
          <Box sx={basicInfoContainer}>
            <Box sx={basicInfoTitle}>Basic info</Box>
            <Grid container sx={basicInfoGridContainer}>
              <Avatar
                sx={userIconContainer}
                onClick={handleModal}
                src={profilePic}
              />
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  First Name
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={state.first_name}
                    onChange={event =>
                      setState({...state, first_name: event.target.value})
                    }
                    sx={{width: '100%'}}
                    disabled={!editBasic}
                  />
                </Grid>
              </Box>
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  Last Name
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={state.last_name}
                    onChange={event => {
                      setState({...state, last_name: event.target.value});
                    }}
                    sx={{width: '100%'}}
                    disabled={!editBasic}
                  />
                </Grid>
              </Box>
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  Birthday
                </Grid>
                <Grid item xs={8}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      value={state.birthdate}
                      onChange={event => setState({...state, birthdate: event})}
                      renderInput={params => (
                        <TextField {...params} sx={{width: '100%'}} />
                      )}
                      disabled={!editBasic}
                    />
                  </LocalizationProvider>
                </Grid>
              </Box>
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  Gender
                </Grid>
                <Grid item xs={8}>
                  <Select
                    sx={{width: '100%', paddingLeft: '10px'}}
                    value={state.gender}
                    disabled={!editBasic}
                    onChange={event =>
                      setState({...state, gender: event.target.value})
                    }
                  >
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'x'}>Another Gender</MenuItem>
                  </Select>
                </Grid>
              </Box>
              <Box sx={bRBox}>
                {editBasic ? (
                  <Box sx={{display: 'flex', gap: 2}}>
                    <BasicBtn
                      name="cancel"
                      action={resetBasicInfo}
                      customSx={discardBtn}
                    />
                    <BasicBtn
                      name="save"
                      action={updateProfile}
                      customSx={saveBtn}
                    />
                  </Box>
                ) : (
                  <Box sx={bRBtn}>
                    <BasicBtn name="edit" action={handleEditBasic} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
          <Box sx={basicInfoContainer}>
            <Box sx={basicInfoTitle}>Contact info</Box>
            <Grid container sx={basicInfoGridContainer}>
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  Email
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={{width: '100%'}}
                    value={state.email}
                    onChange={event =>
                      setState({...state, email: event.target.value})
                    }
                    disabled={!editContact}
                  />
                </Grid>
              </Box>
              <Box sx={basicInfoRow}>
                <Grid item xs={4}>
                  Phone
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={{width: '100%'}}
                    value={state.phone}
                    onChange={event =>
                      setState({...state, phone: event.target.value})
                    }
                    disabled={!editContact}
                  />
                </Grid>
              </Box>
              <Box sx={bRBox}>
                {editContact ? (
                  <Box sx={{display: 'flex', gap: 2}}>
                    <BasicBtn
                      name="cancel"
                      action={resetContactInfo}
                      customSx={discardBtn}
                    />
                    <BasicBtn
                      name="save"
                      action={updateProfile}
                      customSx={saveBtn}
                    />
                  </Box>
                ) : (
                  <Box sx={bRBtn}>
                    <BasicBtn name="edit" action={handleEditContact} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snack}
        onClose={closeSnack}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={6000}
      >
        <Alert severity={severity}>
          <Typography variant="h6">{snackMsg}</Typography>
        </Alert>
      </Snackbar>
      <Modal open={modal} onClose={handleModal}>
        <>
          <PhotoModal
            close={handleModal}
            uploadPic={uploadPic}
            imageUrl={imageUrl}
            handleImgChange={handleImgChange}
          />
        </>
      </Modal>
    </BaseLayer>
  );
};

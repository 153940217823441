import {PasswordForgetView} from '../views/PasswordForgetView/PasswordForgetView';
import RouteErrorView from '../views/RouteErrorView/RouteErrorView';
import {SignInView} from '../views/SignInView/SignInView';
import {TBCView} from '../views/TBCView/TBCView';
import {PublicRoute} from './guards/PublicRoute';

export const publicRoutes = [
  {
    path: '/',
    element: (
      <PublicRoute>
        <SignInView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/password/forget',
    element: (
      <PublicRoute>
        <PasswordForgetView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/tbc',
    element: (
      <PublicRoute>
        <TBCView />
      </PublicRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];
